import dynamic from "next/dynamic";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const LoginForm = dynamic(() => import("../components/forms/LoginForm"));
import Link from "next/link";
import { TOOL_BAR_HEIGHT } from "../lib/settings";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url('/images/bg/wave.svg') no-repeat bottom`,
    backgroundSize: "cover",
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT}px)`,
  },

  heading: {
    fontWeight: 500,

    "& span": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
    },
  },

  list: {
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    "& li": {
      padding: theme.spacing(1, 0),
      paddingLeft: theme.spacing(0.5),
      "&::marker": {
        color: theme.palette.primary.main,
        fontSize: 16,
      },
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
}));
const Login = () => {
  const classes = useStyles();
  return (
    <Box paddingY={[3, 5, 7, 9]} className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h2" className={classes.heading} gutterBottom>
              Why individuals and Enterprises <br />{" "}
              <span>Choose Spoclearn?</span>
            </Typography>

            <ul className={classes.list}>
              <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. </li>
              <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. </li>
              <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography align="right" gutterBottom paragraph>
              Need an Account ?{" "}
              <Link href="/register" className={classes.link}>
                Sign Up
              </Link>
            </Typography>
            <LoginForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Login;
